/* RegisterParent.css */

*, *::before, *::after {
    box-sizing: border-box;
} 

.register-parent-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
    margin-bottom: 20px;
    text-align: center;
}

.profile-card,
.details-card {
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.profile-card {
    background-color: #ffffff;
}

.details-card {
    background-color: #ffffff;
}

.ant-card {
    transition: box-shadow 0.3s ease;
}

.ant-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.ant-form-item {
    margin-bottom: 16px;
}

.ant-input,
.ant-picker {
    border-radius: 4px;
}

.ant-btn {
    margin-top: 20px;
    border-radius: 4px;
}

.inputs_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.inputs_container > div {
    display: flex;
    width: 300px;
    flex-grow: 1 !important; 
}

.inputs_container div div {  
    width: 100%;
    justify-content: space-between;
}

.inputs_container div div input {
    height: 40px;
    flex-grow: 1;
}

.inputs_container div div div div {
    display: flex !important; 
    gap: 10px;
    flex-grow: 1;
}

.inputs_container div div div div div input {
    display: flex;  
    margin: 0 !important; 
}

.inputs_container div div div div div input, .inputs_container select {
    font-size: 14px !important;
}

.register-parent-container {
    display: flex;
    width: 100%;
    flex-direction: column; 
    padding-block: 30px 50px; 
}

.register-parent-container h1 {
    display: flex;
    width: 600px;
    max-width: 100%;
    text-align: left; 
    font-size: 20px;
    margin-block: 30px 0; 
}

.image_institution_name_container {
    display: flex;
    width: 600px;
    max-width: 100%;
    height: 80px; 
    gap: 10px;
}

.image_institution_name_container h2 {
    display: flex;
    height: 100%;
    font-size: 20px;
    text-align: left;
    align-items: center;
}

.hidden_form_input {
    display: none !important;
}

.ant-upload-list-item  {
    margin-bottom: 100px !important;
    background-color: rgb(244,244,244);
    z-index: 10000;
    position: absolute !important;
    top: 0; 
    width: 280px !important;
    max-width: max(200px, calc(100vw - 85px)) !important;
    align-items: center !important;
    padding: 0 !important;  
}

.ant-upload-list-item:hover {
    background-color: rgb(244,244,244) !important;
}

.ant-upload-list-item-actions {
    display: flex;
    height: 100%;
    width: 40px !important;
    background-color: red; 
    justify-content: center;  
    align-items: center;
    cursor: pointer;
    padding-inline: 0 !important;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
}

.ant-upload-list-item-actions button {
    display: flex;
    width: 100% !important;
    height: calc(100% - 4px) !important;
    background-color: red; 
    margin-block: 0 !important;
}

.ant-upload-list-item .ant-btn-icon {
    display: flex;
    width: 100%;
    height: 100% !important; 
    justify-content: center; 
}

.ant-upload-list-item .ant-btn-icon svg { 
    fill: #fff !important; 
}


 
@media (max-width: 768px) {
    /* .row {
        display: flex;
        flex-direction: column;
    }

    .col-md-4 {
        width: 100%;
    }  */
 
    .inputs_container {
        max-width: 100% !important;
        flex-direction: column;
        padding: 0 !important;
    }
    .inputs_container > div {
        display: flex;  
        flex-direction: column; 
        width: 100%;  
    } 
}