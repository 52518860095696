:root {
  --width: fit-content;
  --box-shadow: 1px 1px 2px #00000028, -1px -1px 2px #00000028;
}

.displayed_none {
  display: none !important;
}

.parent_self_registration * {
  font-family: 'Poppins';
}

.parent_self_registration {
  display: flex;
  flex-direction: column;
  width: 500px;
  border-radius: 30px;
  margin-block: 50px;
  margin-inline: auto;
  box-shadow: var(--box-shadow);
  align-items: center;
  padding-block: 30px;
  padding-inline: 30px;
}

.parent_self_registration h1 {
  font-size: 20px;
  width: 100%; 
  font-weight: bold; 
}

.enter_parent_student_details {
  display: flex;
  width: 100%;
  height: fit-content;
  /* background-color: aqua; */
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  margin-block: 30px 0;
  padding-block: 0 30px;
}

.parent_self_registration h2 {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
}

.parent_self_registration input, .parent_self_registration select {
  outline: none;
  cursor: pointer;
}

.parent_self_registration h2 span:nth-of-type(1) {
  font-weight: 16px;
  font-weight: bold; 
}

.parent_self_registration h2 span:nth-of-type(2) {
  display: flex;
  width: 100px;
  height: 2px;
  background-color: #000;
}

.parent_self_registration .form_input {
  display: flex;
  width: 200px;
  min-height: fit-content;
  /* background-color: teal; */
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
  /* flex-grow: 1; */
}

.parent_self_registration .form_input label {
  display: flex;
  width: 100%;
  font-size: 14px;
}

.parent_self_registration .form_input input:nth-of-type(1) {
  display: flex;
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  font-size: 14px;
  padding-inline: 10px;
  font-family: 'Poppins';
}

.parent_self_registration .form_input .phone_input {
  display: flex;
  /* background-color: red; */
}

.parent_self_registration .phone_input select {
  width: 65px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  box-shadow: var(--box-shadow);
  outline: none;
  cursor: pointer;
}

.parent_self_registration .phone_input input {
  box-shadow: 1px 1px 2px #00000028, 0px -1px 2px #00000028;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  outline: none;
  padding-left: 5px;
  cursor: pointer;
}

.input_error {
  border: 1px solid red !important;
  box-shadow: none !important;
}

.error_message, .error_message_single_input {
  display: flex;
  width: 100%;
  height: 40px;
  flex-wrap: wrap;
  border: 1px solid red;
  color: red;
  font-size: 14px;
  align-items: center;
  border-radius: 5px;
  padding-inline: 20px;
  margin-block: 20px 10px;
}

.error_message_single_input {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  border: 1px solid red;
  color: red;
  font-size: 10px;
  align-items: center;
  border-radius: 5px;
  padding-inline: 10px;
  margin-block: 2px;
}

.enter_parent_student_details .select_school_level_class {
  display: flex;
  width: 100%; 
}

.enter_parent_student_details .select_school_level_class select {
  width: 100%;
  border: 0;
  height: 35px;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  padding-inline: 10px;
  font-size: 14px;
}

.add_remove_another_child {
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #000;
  font-size: 14px;
  align-items: center;
  background: none;
  gap: 5px;
  padding-inline: 20px;
  margin-block: 15px 0;
}

.add_child {
  margin-bottom: 20px !important;
}

.add_remove_another_child:hover {
  background-color: #000;
  color: #fff;
}

.add_remove_another_child .add_remove {
  font-size: 25px;
}

.add_border_bottom {
  border-bottom: 1px solid #000;
  margin-block: 0 10px;
}

.terms_and_privacy_acknowledgement {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.terms_and_privacy_acknowledgement p {
  font-size: 12px;
  margin-block: 5px 0;
}

.create_account {
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #35E735;
  border: none;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-block: 20px 10px;
}

.create_account:hover {
  background-color: #000;
  color: #fff;
}

.parent_self_registration .date_of_birth {
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  justify-content: space-between; 
  padding: 0;
}

.parent_self_registration .date_of_birth input {
  display: flex;
  width: 50px !important;
  box-shadow: 1px 1px 2px #00000028, 1px -1px 2px #00000028; 
  outline: none;
  border: none;
  border-radius: 5px; 
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  padding-inline: 0 !important;
}

.parent_self_registration .date_of_birth input:nth-of-type(3) {
  width: 80px !important;
}

.full_width {
  width: 100% !important; 
}

.full_height {
  min-height: 100%; 
}

.parent_self_registration .required_input {
  color: red !important;
}

@media (max-width: 768px) {
  .parent_self_registration {
    /* border: 1px solid red; */
    width: 100%;
  }

  .parent_self_registration .form_input {
    flex-grow: 1;
  }

  .parent_self_registration .form_input .date_of_birth {
    width: 100%;
    /* border: 1px solid brown; */
  }

  .parent_self_registration .form_input .date_of_birth .input {
    width: 30% !important;
    /* border: 1px solid teal; */
  }
}
