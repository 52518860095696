.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding-inline: 50px; 
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 1px 10px #a7a7a7; 
  }
  
  .hamburger {
    display: none;
  }
  
  .left { 
    display: flex;
    width: 200px;
  }
   
  .right {
    display: flex; 
    width: fit-content; 
    gap: 20px;
  }
  
  .link_styles {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
  }
   
  .enrol_your_school_styles {
    padding-inline: 20px;
    border: 1px solid #000;
    border-radius: 20px;
  }
  
  .login_styles {
    background-color: #39B54A;
    color: #fff;
    border-radius: 20px;
    padding-inline: 20px;
  }
  
  .left svg {
    width: 100%;
  }
  
  .right a:first-child:hover {
    color: grey;
  }
  
  .right a:nth-child(2):hover, .right a:nth-child(3):hover {
    color: #fff !important;
    background-color: #000 !important;
  }
  
  /* Styles for the common school nav  */ 
  .school_common_nav {
    position: relative;
  }
  
  .school_common_nav .left a {
    display: flex;
    width: fit-content;
    height: 80px; 
    border-radius: 2px;
    overflow: hidden;
    background-color: transparent;
    color: #fff;
    font-size: 12px; 
  }
  
  .school_common_nav .left a img {
    display: flex;
    width: 100%; 
  }
  
  .right_btn {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 60px;  
    align-items: center;
    justify-content: space-between;
    font-size: 14px;  
  }
  
  .right button:hover span {
    color: grey;
  }
  
  .icon {
    display: flex;
    position: relative;
    width: 40px;
    height: 40px; 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; 
  }

  
  .notifications__indicator {
    display: none;
    width: 15px;
    height: 15px;
    background-color:#39B54A;
    position: absolute;
    top: -3px;
    right: 2px;
    border-radius: 10px;
  }
  
  .profile_pic_user_name_container {  
    display: flex; 
    width: fit-content;
    max-width: 185px;
    height: 60px;  
    align-items: center;
    gap: 5px;   
  }
  

  
  .user_name {
    display: flex;
    width: fit-content;
    max-width: 120px;
    height: fit-content;
    max-height: 56px;
    text-align: left;
    flex-wrap: wrap !important; 
    align-items: center; 
    word-break: break-all;
    font-size: 13px; 
  }
  
  .profile_pic img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .dropdown {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 20px;
    width: 150px;
    height: 300px; 
    z-index: 1.5; 
    overflow: hidden;
  }
  
  .dropdown a {
    height: 60px;
    border: 1px solid transparent; 
    border-bottom-color: #fff;
    background-color: #B2D4F9 !important;
  }
  
  .dropdown a:hover {
    background-color: #fff !important;
     border: 1px solid #84A9D1; 
  }
   
  
  /* Styles for the common school nav  */
  
  @media (max-width: 750px) {
    .nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: fit-content;
      padding-inline: 20px;
      padding-block: 30px;
      background-color: #fff;
      gap: 30px;
    }
  
    .hamburger {
      display: flex;
      width: 50px; 
      height: 50px;
      background-color:#39B54A;
      border-radius: 5px;
    }
    
    .left {
      width: 100%; 
      flex-direction: row;
      height: 50px;
      justify-content: space-between;
    }
    
    .right { 
      display: none;
      width: 100%;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 20px;
    }
    
    .link_styles {
      width: 100%; 
      height: 50px;
    }
    
    .raise_an_enquiry_styles {
      border: 1px solid #000;
      border-radius: 25px;
    }
  
    .raise_an_enquiry_styles:hover {
      background-color: #000;
      color: #fff !important;
    }
  
    .enrol_your_school_styles {
      padding-inline: 20px; 
      border-radius: 25px;
    }
    
    .login_styles { 
      border-radius: 50px;
      padding-inline: 20px;
    }
    
    .left svg {
      width: auto;
      height: 50px;
    }
  
    /* Styles for the common school nav  */ 
    .school_common_nav {
      box-shadow: 0 2px 10px #00000027;
    }
    .school_common_nav .left a {
      display: flex;
      width: fit-content;
      height: 50px; 
      border-radius: 2px;
      overflow: hidden; 
      color: #fff;
      font-size: 10px; 
    }
  
    .school_common_nav .left a img {
      display: flex;
      width: 100%; 
    }
  
    .right_btn {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 76px;  
      align-items: center;
      justify-content: center;
      border: 1px solid #000;
      border-radius: 38px;
      gap: 10px;
      font-size: 16px;  
    }
      
    .profile_pic_user_name_container {  
      display: flex; 
      min-width: 100%;
      height: 76px;  
      align-items: center;
      justify-content: center;
      gap: 10px; 
      border: 1px solid #000;
      border-radius: 38px;
      margin-inline: auto;
    }

  
    .user_name {
      display: flex; 
      max-width: calc(100% - 90px); 
      align-items: center;  
      font-size: 16px;
    }
  
  .dropdown {
    display: none;
    flex-direction: column;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 300px; 
    z-index: 1.5; 
    overflow: hidden; 
  }
   
  .dropdown a {
    height: 76px;
    border: 1px solid #84A9D1; 
    border-bottom-color: #fff;
  }
  
  .dropdown a span {
    display: flex;
  }
   
    /* Styles for the common school nav  */
  
  }