.newsletter-large {
  margin: 1.5rem 1.5rem;
}

.inputs_container, .newsletter_date_time_container {
  display: flex; 
  gap: 10px;
  padding: 0;
  height: fit-content;
  margin-block: 0 10px; 
  border: 1px solid rgb(204,204,204);
  padding-inline: 20px;
  padding-block: 15px; 
}

.newsletter_date_time_container { 
  flex-direction: column;  
}

.newsletter_date_time_container > * {
  margin: 0 !important;
}

.newsletter_date_time_container__inputs {
  display: flex; 
  gap: 30px;
  padding: 0;
  height: fit-content;
  margin-block: 0 10px; 
  flex-wrap: wrap;
}

.newsletter_date_time_container h3 {
  font-weight: normal; 
  font-size: 16px;
}

.main_content_container > button { 
  width: fit-content !important; 
  margin-top: 30px !important;
}

 .student_search_container {
  box-shadow: none !important;
  border: 1px solid rgb(217,217,217) !important;
  border-radius: 0 !important;
  margin-block: 0 15px !important;
  width: 100% !important;
}

 .student_search_container h3 {
  font-size: 16px;
  font-weight: normal;
}
 
 .student_search_container .input_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
 
 .student_search_container input[type="text"] {
  width: 300px;
  padding-inline: 10px;
  outline: none;
}

 .student_search_container button {
  width: 150px;
  height: 40px;
  display: flex;
  border: none;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: 'Poppins';
  background-color: transparent;
}

 .student_search_container button:nth-of-type(1) {
  border: 1px solid green;
  color: green;
}

 .student_search_container button:nth-of-type(2) {
  border: 1px solid red;
  color: red;
}

 .student_search_container button:hover {
  background-color: #000;
  border-color: transparent;
  color: #fff;
}

 .student_search_container select {
  width: 300px;
  height: 40px;
  padding-inline: 10px;
}


@media (max-width: 991px) {
  .main_content_container > button {  
    margin-top: 0 !important; 
  } 
}


@media only screen and (min-width: 600px) {
  .newsletter-large {
      margin: 1.5rem 3rem;
  }
}

@media only screen and (min-width: 768px) {
  .newsletter-large {
      margin: 1.5rem 4rem;
  }
}

@media only screen and (min-width: 992px) {
  .newsletter-large {
      margin: 1.5rem 6rem;
  }
} 

@media only screen and (min-width: 1200px) {
  .newsletter-large {
      margin: 1.5rem 10rem;
  }
}