.filter_report {
  display: flex;
  width: 100%;
  min-height: 30px; 
  margin-block: 0 10px;  
  padding-block: 0 2px;
  border-bottom: 1px solid #3786DB; 
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap; 
}

.filter_report * {
  font-size: 14px; 
}

.filter_report span {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  min-height: 30px;
  align-items: center; 
}
 
.filter_report select {
  display: flex;
  width: 80px;
  height: 30px;
  border: none;
  border-radius: 2px;
  padding-inline: 5px;
  background-color: #d9d9d9; 
  cursor: pointer;  
  outline: none; 
} 

.filter_results_by_year_and_term {
  display: flex;
  width: fit-content;
  height: 30px !important;
  border-radius: 15px;
  border: none;
  background-color: #3786DB;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-inline: 12px;
  color: #fff;
}

.filter_results_by_year_and_term:hover {
  background-color: #000;
}
 