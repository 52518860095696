/* Base layout */
.newsletter-container {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 2rem;
    padding: 1rem;
    margin-top: 1rem;
}

.newsletter-large {
    margin: 1.5rem 1.5rem;
}

/* Upload section */
.upload-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.upload-item {
    background: #fff;
    border-radius: 8px;
}

.upload-item .ant-form-item {
    margin-bottom: 0;
}

/* Upload states and components */
.ant-upload-drag {
    height: 120px !important;
    padding: 1rem !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    background: #fafafa !important;
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px !important;
}

.ant-upload-drag .ant-upload-btn {
    padding: 0.5rem !important;
}

.ant-upload-drag .ant-upload-text {
    font-size: 0.9rem;
    margin: 0.25rem 0 !important;
}

.ant-upload-drag .ant-upload-hint {
    font-size: 0.8rem;
    color: #666;
}

.upload-icon {
    font-size: 24px;
    color: #999;
    margin-bottom: 0.5rem;
}

.upload-text {
    font-size: 0.9rem;
    color: #333;
    margin: 0.25rem 0 !important;
}

.upload-hint {
    font-size: 0.8rem;
    color: #666;
}

/* File upload feedback */
.uploaded-file {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1rem;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    min-height: 120px;
    justify-content: center;
    text-align: center;
    background: #fff;
}

.file-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    background: #f6f6f6;
    padding: 1rem;
    border-radius: 6px;
    color: #666;
    font-size: 0.95rem;
}

.file-preview .anticon {
    color: #52c41a;
    font-size: 1.2rem;
}

.uploaded-file .ant-btn {
    align-self: center;
    margin-top: 0.5rem;
}

/* Newsletter preview section */
.newsletter-preview-section {
    position: sticky;
    top: 2rem;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    height: fit-content;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
}

.newsletter-preview-header {
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newsletter-preview-header h2 {
    color: #333;
    margin: 0;
    font-size: 1.25rem;
}

.newsletter-preview-header .ant-btn-link {
    color: #1890ff;
    font-size: 0.9rem;
    padding: 4px 8px;
    height: auto;
    display: flex;
    align-items: center;
    gap: 6px;
}

.newsletter-preview-header .ant-btn-link:hover {
    color: #40a9ff;
    background: #f0f7ff;
    border-radius: 4px;
}

.newsletter-preview-header .anticon {
    font-size: 1rem;
}

.newsletter-preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.newsletter-preview-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #333;
    margin: 0.75rem 0;
}

.newsletter-preview-content {
    color: #555;
    line-height: 1.5;
    font-weight: normal;
    font-size: 0.95rem;
}

/* Form elements */
.ant-form-item {
    margin-bottom: 1.25rem;
}

.ant-form-item-label > label {
    color: #333;
    font-weight: 500;
}

.ant-input {
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    padding: 8px 12px;
}

.ant-btn-primary {
    height: 40px;
    padding: 0 24px;
    font-size: 1rem;
    border-radius: 6px;
}

/* Audience and communication options */
.audience_channel_of_communication_options {
    background: #fafafa;
    border-radius: 8px;
    padding: 1rem !important;
    margin-bottom: 1.5rem !important;
    border: 1px solid #eee;
}

.audience_channel_of_communication_options > span {
    font-weight: 500;
    color: #333;
    margin-bottom: 0.75rem;
    display: block;
}

.audience_channel_of_communication_options > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 0.75rem;
}

.multiple_recipient_checkbox_container {
    background: #fff;
    padding: 0.75rem 1rem;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
}

/* Rich text editor */
.message_body .ql-editor {
    min-height: 200px;
    background: #fff;
    border-radius: 0 0 8px 8px;
}

.message_body .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fafafa;
    border-color: #e1e1e1;
}

.message_body .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: #e1e1e1;
}

/* Publishing schedule */
.newsletter_date_time_container {
    background: #fafafa;
    border-radius: 8px;
    padding: 1rem !important;
    margin-top: 1rem;
    border: 1px solid #eee;
}

.newsletter_date_time_container h3 {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 1rem;
}

.newsletter_date_time_container__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
}

/* Error states */
.ant-form-item-has-error .ant-input {
    border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-form-item-explain {
    font-size: 0.85rem;
    margin-top: 0.25rem;
}

/* Responsive design */
@media only screen and (max-width: 1200px) {
    .newsletter-container {
        grid-template-columns: 55% 45%;
    }
    .newsletter-large {
        margin: 1.5rem 6rem;
    }
}

@media only screen and (max-width: 1024px) {
    .newsletter-container {
        grid-template-columns: 1fr;
    }
    .newsletter-preview-section {
        position: relative;
        top: 0;
        margin-top: 2rem;
    }
    .newsletter_date_time_container__inputs {
        grid-template-columns: 1fr;
    }
    .newsletter-large {
        margin: 1.5rem 4rem;
    }
}

@media only screen and (max-width: 768px) {
    .upload-section {
        grid-template-columns: 1fr;
    }
    .newsletter-large {
        margin: 1.5rem 3rem;
    }
}