
:root {
  --box-shadow-size: 1px;
  --box-shadow-blur: 3px;
  --box-shadow-color: #6d6d6dbb;
  --container-bg-color: #ffffffef;
  --dark-font-color: #000;
  --light-font-color: #fff;
  --dark-bg-color: #000;
  --danger-color: red;
  --border-color: #000;
  --light-bg-color: #fff;
  --main-border-radius: 10px;
  --medium-border-radius: 5px;
}

.search_for_stats {
  display: flex; 
  margin-inline: auto;
  flex-wrap: wrap;
  padding-inline: 30px;
  padding-block: 50px;
  gap: 10px;
  /* background-image: url("../../public/hero.mp4");
  background-attachment: fixed;
  background-size: contain; */
}

.search_for_stats * {
  font-family: Poppins;
}

.search_for_stats h1 {
  display: flex;
  width: 100%;
  font-size: 25px;
  padding-inline: 175px 20px;
  padding-block: 25px;
  background-color: var(--container-bg-color);
  border-radius: var(--main-border-radius);
  color: var(--dark-font-color);
  box-shadow: var(--box-shadow-size) var(--box-shadow-size) var(--box-shadow-blur) var(--box-shadow-color), calc(-1 * var(--box-shadow-size)) 0 var(--box-shadow-blur) var(--box-shadow-color);
  background-image: url("../../public/logo.png"); 
  background-size: auto 80%;
  background-position: 10px center;
  background-repeat: no-repeat; 
}

.search_and_search_results_container:nth-of-type(1), .search_and_search_results_container:nth-of-type(2) {
  margin-block: 10px 0;
}

.search_for_stats h2 {
  width: 100%;
  font-size: 18px;
  color: var(--dark-font-color);
}

.search_and_search_results_container {
  display: flex;
  width: 500px;
  min-height: fit-content;
  background-color: var(--container-bg-color);
  flex-direction: column;
  flex-grow: 1;
  border-radius: var(--main-border-radius);
  padding-inline: 20px;
  padding-block: 15px;
  box-shadow: var(--box-shadow-size) var(--box-shadow-size) var(--box-shadow-blur) var(--box-shadow-color), calc(-1 * var(--box-shadow-size)) 0 var(--box-shadow-blur) var(--box-shadow-color);
  gap: 15px; 
}

.search_for_stats .input_container {
  display: flex;
  width: 100%;
  min-height: fit-content;
  /* background-color: red; */
  flex-wrap: wrap;
  gap: 10px;
}

.search_for_stats .input_container input[type="text"] {
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: var(--medium-border-radius);
  border: none;
  outline: none;
  padding-inline: 10px;
  font-size: 16px;
  border: 1px solid var(--border-color);
}

.search_for_stats .input_container button {
  display: flex;
  width: fit-content;
  padding-inline: 20px;
  height: 40px;
  border-radius: 20px;
  border: none;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  background-color: transparent;
  color: var(--dark-font-color)
}

.search_for_stats .input_container select {
  display: flex;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--medium-border-radius);
  padding-inline: 10px; 
  height: 40px;
  outline: none;
}

.search_for_stats .input_container button:nth-of-type(1) {
  /* background-color: var(--dark-bg-color); */
  --border-color: #000; 
  color: var(--border-color);
}

.search_for_stats .input_container button:nth-of-type(2) {
  /* background-color: var(--danger-color); */
  --border-color: red;
  color: var(--border-color);
}

.search_for_stats .input_container button:hover {
  background-color: var(--border-color);
  color: var(--light-font-color);
}

.results_container {
  margin-block: 10px 0;
}

.results_container h2 {
  margin-block: 0 10px;
}


.search_for_stats ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  gap: 10px;
}

.search_for_stats li {
  list-style-type: none;
  display: flex;
  width: 100%;
  background-color: #f0f0f0;
  flex-direction: column;
  border-radius: var(--medium-border-radius);
  padding-inline: 15px;
  padding-block: 10px;
  border-top: 3px solid rgba(0, 128, 0, 0.274);
}

.search_for_stats ul ul {
  display: flex;
  border: 2px solid var(--light-bg-color);
  border-radius: var(--medium-border-radius);
  padding-inline: 0;
  padding-block: 10px;
  margin-block: 0 10px;
}

.search_for_stats ul ul li {
  display: flex;
  width: 100%; 
  border-top: none; 
  padding-inline: 10px;
  padding-block: 2px;
}

.search_for_stats ul ul h3 {
  display: flex;
  width: 100%;
  padding-inline: 10px;
  font-size: 16px;
  margin-block: 0; 
}

@media (max-width: 768px) {
  .search_for_stats h1 {
    display: flex;
    width: 100%;
    font-size: 18px;
    padding-inline: 90px 20px; 
    background-image: url("../../public/logo.png"); 
    background-size: auto 30px;
    background-position: 10px center;
    background-repeat: no-repeat; 
    flex-wrap: wrap;
  }

  .search_for_stats h2, .search_for_stats ul ul h3 {
    font-size: 16px;
  }
}


