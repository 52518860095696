/* Blog Post Style CSS - Wider layout with updated text */
.blog-post-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .blog-header {
    background-color: #f5f5f5;
    padding: 40px 0;
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .school-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  
  .blog-header h1 {
    font-size: 32px;
    font-weight: 300;
    color: #333;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .blog-navigation {
    margin-top: 10px;
    font-size: 16px;
  }
  
  .nav-link {
    color: #333;
    text-decoration: none;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
  .nav-separator {
    margin-left: 10px;
    color: #999;
  }
  
  .blog-content {
    max-width: 1400px; /* Increased from 1200px for even wider content */
    margin: 0 auto;
    padding: 0 30px;
    width: 90%; /* Ensure it takes up most of the screen */
  }
  
  .featured-image {
    width: 100%;
    margin-bottom: 40px;
  }
  
  .featured-image img {
    width: 100%;
    height: auto;
    max-height: 600px;
    object-fit: cover;
  }
  
  .post-metadata {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 16px;
    float: left;
    width: 200px; /* Increased from 180px */
  }
  
  .metadata-item {
    margin-bottom: 16px;
  }
  
  .metadata-label {
    color: #999;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .metadata-value {
    font-weight: 600;
    color: #333;
  }

  .metadata-attachment {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #eee;
  }
  
  .metadata-attachment h3 {
    font-size: 14px;
    color: #999;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .metadata-attachment a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #fff;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    transition: all 0.2s ease;
  }
  
  .metadata-attachment a:hover {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
  }
  
  .metadata-attachment a::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333'%3E%3Cpath d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .post-title {
    margin-left: 240px; /* Increased from 220px */
    margin-bottom: 30px;
  }
  
  .post-title h2 {
    font-size: 48px; /* Increased from 46px */
    color: #333;
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
  }
  
  .post-content {
    margin-left: 240px; /* Increased from 220px */
    line-height: 1.8;
    color: #333;
    font-size: 18px;
    width: calc(100% - 260px); /* Ensure content doesn't overflow */
  }
  
  .post-content p {
    margin-bottom: 24px;
  }
  
  .download-attachment {
    margin-top: 40px;
  }
  
  .download-attachment a {
    display: inline-block;
    padding: 12px 24px;
    background-color: #f5f5f5;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .download-attachment a:hover {
    background-color: #e0e0e0;
  }
  
  .footer {
    display: flex;
    width: 100%;
    min-height: 120px;
    background-color: #f5f5f5;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 80px;
    padding: 40px 0;
  }
  
  .footer span, .footer a {
    font-size: 16px;
    color: #666;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Responsive Design */
  @media only screen and (max-width: 1200px) {
    .post-metadata {
      float: none;
      width: 100%;
      margin-bottom: 30px;
    }
    
    .post-title, .post-content {
      margin-left: 0;
      width: 100%;
    }
    
    .post-title h2 {
      font-size: 42px;
    }
    
    .metadata-item {
      display: inline-block;
      margin-right: 20px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .blog-content {
      padding: 0 60px;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .blog-content {
      padding: 0 80px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .blog-content {
      padding: 0 120px;
    }
  }

  .attachments-section {
    margin-top: 40px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .attachments-section h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 500;
  }
  
  .download-attachment {
    margin-top: 0;
  }
  
  .download-attachment a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    background-color: #fff;
    color: #333;
    text-decoration: none;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    transition: all 0.3s ease;
  }
  
  .download-attachment a:hover {
    background-color: #f5f5f5;
    border-color: #d0d0d0;
  }
  
  .download-attachment a::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23333'%3E%3Cpath d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
  }