ul.ant-menu li.ant-menu-submenu div[role='menuitem'] {
    margin: 0 0 5px;
    width: 100%;
    border-radius: 0;
}

.ant-menu-item-selected {
    color: #39b54a;
    background-color: #39b54a;
    border-right: 3px solid #39b54a;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark .ant-menu-item-selected, :where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark>.ant-menu .ant-menu-item-selected {
    color: #d9e3db;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active, :where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #39b54a;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #39b54a;
}

ul.ant-menu.ant-menu-sub.ant-menu-vertical {
    border-radius: 0;
}

.ant-tabs-top > .ant-tabs-nav::before { /* For the line to the right and close to the tabs */
    border-color: #dadada;
}


::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.37);
    border-radius: 10px;
}

.blue-float-btn{
    background: #2ba5d2 !important;
}

:where(.css-dev-only-do-not-override-1xqp6xr).ant-float-btn-primary {
    background-color: #2ba5d2 !important;
}

.only-print {
    display: none;
}

.table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #969ca2;
    padding-bottom: 10px;
    margin-top: 14px;
}

.card-bg-light {
    background-color: #d7d7d7
}

.text-warn {
    color: #fa7414;
}

.ql-editor{
    min-height: 600px;
    overflow: auto;
}

.fill-window {
    margin-top: 64px;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*overflow: hidden;*/
}

@media print {
    .hide-print {
        display: none
    }

    .print-margins {
        margin: 24px 32px;
    }

    .print-center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .only-print {
        display: block;
    }
}

/* Created classes and hover states for the back button on the student/account/edit page */
.back_button { 
    background-color: transparent;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.back_button:hover { 
    background-color: #969ca2;
    border-color: #969ca2 !important; 
}

.back_button:hover * {
    color: #ffffff !important;
}
/* Created classes and hover states for the back button on the student/account/edit page */


/* Created classes and hover states for the clear birth certificate input button on the admin/students page */
.upload_student_birth_certificate_input {
    position: relative;
}

.clear_birth_certificate_upload {
    display: flex;
    position: absolute;
    top: 50%; 
    transform: translateY(calc(-1 * (50% + 10px)));
    rotate: 45deg;
    right: min(10%, 20px);
    border: none !important;
    font-size: 40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center; 
    align-items: center;
    background: none;
    box-shadow: none;
    color: red; 
}

.clear_birth_certificate_upload:hover {
    color:#969ca2 !important; 
}
/* Created classes and hover states for the clear birth certificate input button on the admin/students page */

.ant-table-content {
    overflow-x: auto; 
}

/* Bulk upload modal styling */
.bulk-upload-modal .ant-upload-list {
    margin: 24px 0;
}

.bulk-upload-modal .ant-upload-list-item {
    margin-top: 16px !important;
}

.bulk-upload-modal .ant-upload-list-item-card-actions {
    opacity: 1;
    position: relative;
    top: -8px;
}

.bulk-upload-modal .ant-modal-title {
    margin-bottom: 24px !important;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
}

.bulk-upload-modal .ant-upload-drag {
    padding: 16px;
}