.admin_type_selection {
  display: flex;
  /* background: salmon; */
  flex-wrap: wrap;
  gap: 20px; 
  margin-block: 0 20px;
}

.admin_type_container {
  display: flex;
  width: fit-content;
  /* background: green; */
  align-items: center;
  gap: 10px;
}

.admin_type_container label, .admin_type_container input {
  cursor: pointer;
}

.admin_type_container label {
  font-weight: bold;
}

.admin_type_container input {
  width: 18px; 
  height: 18px;  
}