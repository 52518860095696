.user-nav-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.ant-nav-bar {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
}

.horizontal_sticky_menu_container {
  display: none;
}

body {
  position: relative;
}

.download_mobile_app_container {
  display: flex;
  width: 210px;
  height: 100px;
  align-items: flex-start;
  background-color: #fff;
  /* position: fixed;
    top: 450px;
    left: 10px; */
  /* transform: translateX(-50%); */
  z-index: 5;
  /* box-shadow: 1px 1px 10px #1a1919, -1px -1px 10px #868686; */
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  padding-block: 5px 0;
  margin-top: 10px !important;
  margin-inline: auto;
  gap: 5px;
  cursor: pointer;
  z-index: 11000 !important;
}

.download_mobile_app_container h3 {
  display: flex;
  width: 100%;
  font-size: 12px;
  justify-content: center;
  padding-block: 10px;
  font-weight: 500;
  margin-block: 0;
}

.download_mobile_app_container a {
  display: flex;
  width: fit-content;
  height: 35px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  padding-inline: 40px 10px;
  background-image: url("../../public/google_play_store.svg");
  background-size: auto 80%;
  background-position: 8px center;
  background-repeat: no-repeat;
  text-decoration: none;
  font-weight: 500;
  margin-block: 0;
}

.download_mobile_app_container a:hover {
  background-color: #fff;
  color: #000;
}

.collapsed {
  width: 110px;
  height: 50px;
  background-image: url("../../public/google_play_store.svg");
  background-size: auto 50%;
  background-position: 0 center;
  background-repeat: no-repeat;
  text-decoration: none;
  font-weight: 500;
  margin-block: 0;
  padding-block: 0;
  padding-inline: 30px 0px;
  font-size: 11px;
}

.collapsed h3 {
  font-size: 12px;
}

@media screen and (max-width: 991px) {
  .sidebar_menu {
    display: none;
  }

  .site-layout {
    margin-inline: 0 !important;
  }

  .ant-table-content {
    overflow-x: auto !important;
  }

  .sticky_menu_hidden {
    display: flex;
    margin-left: calc(100% - 85px);
    width: 75px;
    height: 55px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 0 !important;
    background-color: #001529cc;
    border: none !important;
    cursor: pointer;
    border-radius: 5px !important;
  }

  .sticky_menu_hidden p {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
  }

  .horizontal_sticky_menu_container {
    display: flex;
    flex-wrap: wrap;
    /* background: #001529; */
    border: 1px solid #001529;
    border-radius: 15px;
    min-height: 50px;
    position: sticky;
    top: 75px;
    left: 0;
    z-index: 2;
    gap: 5px;
    padding-block: 5px;
    padding-inline: 2px;
    margin-block: 0 10px;
  }

  .horizontal_sticky_menu_container a {
    display: flex;
    width: fit-content;
    height: 30px;
    text-decoration: none;
    /* border: 1px solid #fff; */
    background: #001529;
    font-size: 12px;
    color: #fff;
    border-radius: 10px;
    padding-inline: 10px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .horizontal_sticky_menu_container a svg {
    width: 10px;
    height: auto;
    fill: #fff;
  }

  .horizontal_sticky_menu_container a.logout {
    background-color: red;
  }

  .download_mobile_app_container {
    width: fit-content;
    height: fit-content;
    padding-block: 0;
    margin: 0 !important;
    background-image: url("../../public/google_play_store.svg");
    background-size: auto 75%;
    background-position: 2px center;
    background-repeat: no-repeat;
  }

  .download_mobile_app_container a {
    background-color: transparent;
    color: #000;
    padding-left: 26px;
  }

  .download_mobile_app_container a:hover {
    background-color: transparent;
    color: #000;
  }
}

@media screen and (max-width: 991px) {
  .site-layout {
    margin-left: 56px;
  }

  .ant-nav-bar {
    padding: 0 10px;
  }
}

@media screen and (min-width: 991px) {
  .site-layout {
    margin-left: 250px;
  }
}
