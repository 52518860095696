.banner-image {
    height: 50vh;
}

.login-container {
    height: 100vh;
    background: #e5e5e5;
}

.ads-banner {
    min-height: 100px;
}

.advert-image {
    margin: 0 40px;
}

.login-card {
    min-height: 50vh;
}

.powered_by {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
}

.powered_by a {
    display: flex;
    color: #000; 
    font-size: 16px;
    font-weight: 500;
}

.main__container {
    display: flex;
    background-color: #fff; 
    flex-direction: column;
    justify-content: center !important;
}

.main__container form {
    display: flex;
    max-width: 500px;
    height: fit-content;
    border-radius: 30px !important;
    box-shadow: 1px 1px 3px #858585, -1px -1px 3px #858585; 
    flex-direction: column;
    padding-block: 0 20px;
    padding-inline: 30px !important;
    margin-block: 30px !important;
}

.main__container .school_logo_container {
    display: flex;
    width: 150px;
    height: 150px;
    margin-inline: auto;
    margin-block: 0 5px !important;
    /* border: 1px solid red; */
}

.main__container .school_logo_container img {
    object-fit: contain;
}

.decoration_hr_line {
    display: flex;  
    margin-block: 5px 10px !important;
    margin-inline: auto;
}

.entire_screen_spinner_container {
    display: flex;
    width: 100%;
    height: 100dvh; 
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .login-card {
        min-width: 370px;
        min-height: 50vh;
    }

    .advert-image {
        height: 80px;
    }

}

@media (max-width: 768px) {
    body { 
        padding-bottom: 200px !important; 
    }

    .main__container form {
        display: flex;
        max-width: 100%;
        height: fit-content;
        border-radius: 30px !important;
        box-shadow: 1px 1px 3px #858585, -1px -1px 3px #858585; 
        flex-direction: column;
        padding-block: 0 20px;
        padding-inline: 10px !important;
        margin-block: 30px !important; 
    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .moreProducts {
        display: none;
    }
}