.login-auth-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-auth-container {
    display: flex;
    width: 500px !important;
    max-width: 100%;
    flex-direction: column;
    /* background-color: green; */
}

.login-auth-container form {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
}

.login-auth-main-container > h1{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
}

.login-auth-main-container > div > h1{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-block: 30px 30px;
}

.login-auth-container > h2{
    text-align: center; 
    margin-block: 0 10px;
}

.login-auth-container{
    border: 1px solid black;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.login-auth-form-group{ 
    margin-block: 20px 0; 
}

.login-auth-form-group input{
    width: 100%;
    background: #E8E8E8;
    border-radius: 15px;
    padding-left: 20px;
}

.login-auth-forgot-password{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-auth-checkbox > div{
    margin-right: 10px;
}
.login-auth-checkbox{
    display: flex;
    gap: 8;
    align-items: center;
}
.login-auth-submit-button{
    background-color: #38B349;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin-block: 10px 0;
    color: white; 
    text-align: center; 
    align-items: center;
}

.login-auth-submit-button:hover {
    background-color: #000;
}

.login-auth-terms-and-conditions{
    text-align: center;
    margin-top: 20px;
}

.login-auth-terms-and-conditions a {
    text-decoration: underline;
}

.login-auth-terms-and-conditions a:hover {
    color: grey;
}

.password-reset-success {
    margin: auto;
    text-align: center;
}
.password-reset-success img{
    width: 30px;
    margin: auto;
    text-align: center;
}
@media only screen and (max-width: 750px) {
    .login-auth-container{
        border: 1px solid black; 
        border-radius: 15px;
        max-width: calc(100% - 20px);
        margin-block: 20px 50px; 
        margin-inline: 10px; 
    }
}