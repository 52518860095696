
:root {
  --box-shadow-size: 1px;
  --box-shadow-blur: 3px;
  --box-shadow-color: #6d6d6dbb;
  --container-bg-color: #ffffffef;
  --dark-font-color: #000;
  --light-font-color: #fff;
  --dark-bg-color: #000;
  --danger-color: red;
  --border-color: #000;
  --light-bg-color: #fff;
  --main-border-radius: 10px;
  --medium-border-radius: 5px;
}

.search_for_students {
  display: flex; 
  width: 100% !important;
  margin-inline: auto;
  margin-block: 0 30px;
  flex-wrap: wrap; 
  gap: 10px;  
  flex-grow: 1; 
}

.search_for_students * {
  font-family: 'Poppins';
}

.search_for_students h1 {
  display: flex;
  width: 100%;
  font-size: 18px;
  padding-inline: 20px !important;
  padding-block: 25px;
  /* background-color: var(--container-bg-color); */
  background-color: transparent;
  border-radius: var(--main-border-radius);
  background-image: none !important;
  color: var(--dark-font-color);
  box-shadow: var(--box-shadow-size) var(--box-shadow-size) var(--box-shadow-blur) var(--box-shadow-color), calc(-1 * var(--box-shadow-size)) 0 var(--box-shadow-blur) var(--box-shadow-color);
}

.search_and_search_results_container:nth-of-type(1), .search_and_search_results_container:nth-of-type(2) {
  margin-block: 10px 0;
}

.search_for_students h2 {
  width: 100%;
  font-size: 16px;
  color: var(--dark-font-color);
}

.search_and_search_results_container {
  display: flex;
  min-width: 500px !important;
  max-width: 100% !important;
  min-height: fit-content;
  background-color: transparent !important;
  flex-direction: column;
  flex-grow: 1;
  border-radius: var(--main-border-radius);
  padding-inline: 20px;
  padding-block: 15px;
  box-shadow: var(--box-shadow-size) var(--box-shadow-size) var(--box-shadow-blur) var(--box-shadow-color), calc(-1 * var(--box-shadow-size)) 0 var(--box-shadow-blur) var(--box-shadow-color);
  gap: 15px; 
  margin-inline: 0 !important;
}

.search_for_students .input_container {
  display: flex;
  width: 100%;
  min-height: fit-content; 
  flex-wrap: wrap;
  gap: 10px;
}

.search_for_students .input_container input[type="text"] {
  display: flex;
  width: 100%;
  max-width: 500px;
  height: 40px;
  border-radius: var(--medium-border-radius);
  border: none;
  outline: none;
  padding-inline: 10px;
  font-size: 14px;
  border: 1px solid var(--border-color);
  background-color: var(--container-bg-color);
}

.search_for_students .input_container button {
  display: flex;
  width: fit-content;
  padding-inline: 20px;
  height: 40px;
  border-radius: 20px;
  border: none;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  background-color: var(--container-bg-color);
  color: var(--dark-font-color)
}
 
.search_for_students .input_container button:nth-of-type(1) { 
  --border-color: #000; 
  color: var(--border-color);
}

.search_for_students .clear_search { 
  --border-color: red;
  color: var(--border-color);
}

.search_for_students .input_container button:hover {
  background-color: var(--border-color);
  color: var(--light-font-color);
}

.search_for_students button.search_using_query {
  background-color: var(--dark-bg-color);
  color: var(--light-bg-color) !important;
}

.search_for_students button.search_using_query:hover {
  background-color: var(--light-bg-color);
  color: var(--dark-color) !important;
}

.search_for_students .results_container {
  margin-block: 0;
  overflow-x: auto;
}

.search_for_students h2 {
  margin-block: 10px 0;
}

.search_for_students h3 {
  display: flex;
  width: 100%; 
  font-size: 14px;
  margin-block: 10px 0; 
}

.search_by_class {
  display: flex; 
  gap: 10px;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 40px;
  border: 1px dotted var(--border-color); 
  padding-inline: 1px; 
  padding-block: 1px; 
  border-radius: var(--medium-border-radius);
}

.search_by_class select {
  width: 150px;
  height: 38px;
  border: 1px solid var(--border-color);
  border-radius: var(--medium-border-radius);
  cursor: pointer;
  outline: none;
  background-color: var(--container-bg-color);
}

.search_by_class button {
  height: 38px !important;
}

.results_container table { 
  width: 100%; 
  border: 1px solid var(--border-color);  
  background-color: var(--container-bg-color); 
  margin-block: 0 !important;
}

.results_container table tr { 
  border-bottom: 1px solid var(--border-color);
}

.results_container table th, .results_container table tr td {
  min-width: 110px; 
  border-left: 1px solid var(--border-color);
  padding-inline: 5px;
  padding-block: 5px;
}

.results_container table tr td {
  flex-wrap: wrap;
  word-break: break-all; 
}
 
@media (max-width: 768px) {
  .search_for_students h1 {
    display: flex;
    width: 100%;  
  }

  .search_for_students {
    display: flex; 
    width: 100% !important;
    margin-inline: auto;
    margin-block: 0 30px;
    flex-wrap: wrap; 
    gap: 10px;  
    flex-grow: 1; 
  }
   
  .search_and_search_results_container {
    display: flex;
    min-width: 100% !important;  
    padding-inline: 10px !important;
  }
  
  .search_for_students .input_container {
    display: flex;
    max-width: 100%; 
    height: fit-content !important; 
  }
  
  .search_for_students .input_container input[type="text"] {
    display: flex;
    width: 100%;
    max-width: 100%; 
  }  

  .results_container table { 
    width: 100%; 
    border: 1px solid var(--border-color);  
    background-color: var(--container-bg-color); 
    margin-block: 0 !important;
  } 
 
}


