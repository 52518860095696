.loading {
  display: flex;
  z-index: 10;
  width: 100%;
  height: 300vh;
  justify-content: center;
  align-items: flex-start;
  padding-top: 300px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000b9;
  z-index: 10000000000000;
}

.loading__modal {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 300px;
  border-radius: 30px;
  background-color: #fff;
  padding-inline: 50px;
  padding-block: 50px;
  align-items: center;
  gap: 50px;
  position: relative;
}

.loading__modal img {
  width: 80px;
}

.loading h3 {
  font-size: 18px;
  text-align: center;
}

@media (max-width: 750px) {
  .loading {
    display: flex;
    z-index: 10;
    width: 100%;
    height: 300vh; 
    padding-top: 100px; 
  }
  
  .loading__modal { 
    width: 98%;
    height: 300px; 
  } 
}