.parent_name_container {
  color: #fff;
  display: flex;
  width: calc(100% - 100px);
  justify-content: flex-end; 
}

.students {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-block: 20px 50px;
  justify-content: flex-start;
  gap: 20px;
  padding-inline: 30px;
  /* justify-content: space-between; */
}

.students h2 {
  width: 100%; 
  font-size: 20px; 
}

.student_container {
  display: flex;
  position: relative;
  width: 300px;
  min-height: 180px;
  background-color: #fff;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10px;
  padding-inline: 20px;
  padding-block: 15px 0;
  gap: 10px;
  box-shadow: 1px 1px 3px #838383a1, 0 -1px 3px #838383a1;
}

.student_parent_registration_status {
  display: flex;
  width: 20px;
  height: 15px;
  background-color: red;
  position: absolute;
  top: 0;
  right: 0; 
  border-top-right-radius: 10px;
}

.registration_completed_color {
  background-color: green;
}

.student_container a , .registration_completed_status {
  display: flex;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid red;
  color: red;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.registration_completed_status {
  border-radius: 0;
  background-color: green;
  border-color: transparent;
  color: #fff;
}



.student_container a:hover {
  background-color: red;
  color: #fff;
}