.student_info_container {
  display: flex;
  flex-direction: column;
  padding-inline: 0;
  /* border: 1px solid grey; */
  border-radius: 10px;
  gap: 0 !important;
}

.student_info_container .link_to_student_profile {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #000;
  /* margin-inline: auto; */
  margin-block: 0 !important;
  border: 1px solid #000;
  margin-inline: auto;
  padding-inline: 10px;
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.student_info_container .link_to_student_profile:hover {
  background-color: transparent;
  color: #000;
}


@media (max-width: 991px) {
  .student_info_container .link_to_student_profile { 
    width: calc(100% - 20px);  
  }
}