.all_parents_container button {
  display: flex;
  width: fit-content;
  height: 36px;
  border: 1px solid #000;
  border-radius: 18px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding-inline: 15px;
  background-color: #001529;
  color: #fff;
  border-color: #fff;
}
 
.all_parents_container button:hover {
  background-color: transparent;
  color: #000;
  border-color: #001529;
  flex-wrap: wrap;
}